import './Projets.css';
import logo_bibi from '../../img/logo_bibi.png';
import logo_beacon from '../../img/logo_beacon.png';
import logo_cegid from '../../img/logo_cegid.png';
import logo_evan from '../../img/logo_evan.png';
import { Link } from 'react-router-dom';

function Projets() {
    return (
        <div className='Projets'>
            <div className='Projets_title'>
                <svg className='Title_svg' width="43" height="37" viewBox="0 0 43 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M28.4063 8.77704C28.0002 8.76643 27.6002 8.87782 27.2581 9.09685C26.9159 9.31587 26.6473 9.63245 26.4869 10.0057C26.3265 10.3789 26.2818 10.7917 26.3584 11.1906C26.435 11.5896 26.6295 11.9564 26.9167 12.2437L37.5333 22.7791L26.9167 33.3145C26.5363 33.6954 26.3226 34.2117 26.3226 34.75C26.3226 35.2882 26.5363 35.8045 26.9167 36.1854C27.2975 36.5658 27.8138 36.7794 28.3521 36.7794C28.8904 36.7794 29.4066 36.5658 29.7875 36.1854L41.8667 24.2145C42.2471 23.8337 42.4607 23.3174 42.4607 22.7791C42.4607 22.2408 42.2471 21.7246 41.8667 21.3437L29.7875 9.37287C29.6101 9.18457 29.396 9.03457 29.1585 8.9321C28.921 8.82963 28.665 8.77686 28.4063 8.77704Z" fill="black" />
                    <path d="M2.54167 0.218758C2.00513 0.225771 1.49254 0.44203 1.11312 0.821453C0.733694 1.20088 0.517436 1.71347 0.510422 2.25001V22.7792C0.517436 23.3157 0.733694 23.8283 1.11312 24.2077C1.49254 24.5872 2.00513 24.8034 2.54167 24.8104H40.4583C40.9971 24.8104 41.5137 24.5964 41.8947 24.2155C42.2756 23.8346 42.4896 23.3179 42.4896 22.7792C42.4896 22.2405 42.2756 21.7238 41.8947 21.3429C41.5137 20.9619 40.9971 20.7479 40.4583 20.7479H4.57292V2.25001C4.56591 1.71347 4.34965 1.20088 3.97023 0.821453C3.5908 0.44203 3.07821 0.225771 2.54167 0.218758Z" fill="black" />
                </svg>
                <p className='Title_text'>Projets</p>
            </div>
            <div>
                <p className='date'>2024</p>
                <div className="container_cards">
                    <Link to="details/1" className="cards">
                        <div className="cards_div">
                            <p className="cards_title">Bibi Sorties</p>
                        </div>
                        <div className="cards_div_tech">
                            <p className="cards_div_tech_text">Branding</p>
                            <p className="cards_div_tech_text">UX/UI</p>
                            <p className="cards_div_tech_text">Développement web</p>
                            <p className="cards_div_tech_text">Développement mobile</p>
                            <svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.557983 9.53807C0.557983 9.93594 0.720302 10.3174 1.00923 10.5987C1.29816 10.8801 1.69003 11.0381 2.09863 11.0381L20.3412 11.0379L14.8567 16.4167C14.5846 16.7011 14.4364 17.0772 14.4435 17.4658C14.4505 17.8545 14.6122 18.2251 14.8945 18.5C15.1767 18.7749 15.5576 18.9323 15.9567 18.9391C16.3558 18.946 16.7421 18.8017 17.0342 18.5368L25.1481 10.5979C25.4366 10.3166 25.5986 9.93539 25.5986 9.53784C25.5986 9.1403 25.4366 8.75911 25.1481 8.47782L16.5986 0.750861C16.4576 0.603471 16.2875 0.485287 16.0985 0.403294C15.9095 0.321301 15.7055 0.277225 15.4987 0.273671C15.2918 0.270117 15.0863 0.30716 14.8945 0.38261C14.7026 0.45806 14.5284 0.57033 14.3821 0.712783C14.2358 0.855237 14.1204 1.02488 14.043 1.21167C13.9655 1.39847 13.9274 1.59849 13.9311 1.79992C13.9347 2.00135 13.98 2.19994 14.0642 2.38396C14.1484 2.56798 14.2698 2.73355 14.4212 2.87089L20.3412 8.03781L2.09863 8.03804C1.24819 8.03804 0.557983 8.70998 0.557983 9.53807Z" fill="black"/>
                            </svg>
                        </div>
                        <div className='div_img_hover'>
                            <img src={logo_bibi} ></img>
                        </div>
                    </Link>
                    <Link to="details/2" className="cards">
                        <div className="cards_div">
                            <p className="cards_title">Cegid - Exalog</p>
                        </div>
                        <div className="cards_div_tech">
                            <p className="cards_div_tech_text">Branding</p>
                            <p className="cards_div_tech_text">UX/UI</p>
                            <p className="cards_div_tech_text">Développement web</p>
                            <svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.557983 9.53807C0.557983 9.93594 0.720302 10.3174 1.00923 10.5987C1.29816 10.8801 1.69003 11.0381 2.09863 11.0381L20.3412 11.0379L14.8567 16.4167C14.5846 16.7011 14.4364 17.0772 14.4435 17.4658C14.4505 17.8545 14.6122 18.2251 14.8945 18.5C15.1767 18.7749 15.5576 18.9323 15.9567 18.9391C16.3558 18.946 16.7421 18.8017 17.0342 18.5368L25.1481 10.5979C25.4366 10.3166 25.5986 9.93539 25.5986 9.53784C25.5986 9.1403 25.4366 8.75911 25.1481 8.47782L16.5986 0.750861C16.4576 0.603471 16.2875 0.485287 16.0985 0.403294C15.9095 0.321301 15.7055 0.277225 15.4987 0.273671C15.2918 0.270117 15.0863 0.30716 14.8945 0.38261C14.7026 0.45806 14.5284 0.57033 14.3821 0.712783C14.2358 0.855237 14.1204 1.02488 14.043 1.21167C13.9655 1.39847 13.9274 1.59849 13.9311 1.79992C13.9347 2.00135 13.98 2.19994 14.0642 2.38396C14.1484 2.56798 14.2698 2.73355 14.4212 2.87089L20.3412 8.03781L2.09863 8.03804C1.24819 8.03804 0.557983 8.70998 0.557983 9.53807Z" fill="black"/>
                            </svg>
                        </div>
                        <div className='div_img_hover'>
                            <img src={logo_cegid} ></img>
                        </div>
                    </Link>
                    <Link to="details/3" className="cards">
                        <div className="cards_div">
                            <p className="cards_title">Be@con</p>
                        </div>
                        <div className="cards_div_tech">
                            <p className="cards_div_tech_text">Branding</p>
                            <p className="cards_div_tech_text">UX/UI</p>
                            <p className="cards_div_tech_text">Développement mobile</p>
                            <svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.557983 9.53807C0.557983 9.93594 0.720302 10.3174 1.00923 10.5987C1.29816 10.8801 1.69003 11.0381 2.09863 11.0381L20.3412 11.0379L14.8567 16.4167C14.5846 16.7011 14.4364 17.0772 14.4435 17.4658C14.4505 17.8545 14.6122 18.2251 14.8945 18.5C15.1767 18.7749 15.5576 18.9323 15.9567 18.9391C16.3558 18.946 16.7421 18.8017 17.0342 18.5368L25.1481 10.5979C25.4366 10.3166 25.5986 9.93539 25.5986 9.53784C25.5986 9.1403 25.4366 8.75911 25.1481 8.47782L16.5986 0.750861C16.4576 0.603471 16.2875 0.485287 16.0985 0.403294C15.9095 0.321301 15.7055 0.277225 15.4987 0.273671C15.2918 0.270117 15.0863 0.30716 14.8945 0.38261C14.7026 0.45806 14.5284 0.57033 14.3821 0.712783C14.2358 0.855237 14.1204 1.02488 14.043 1.21167C13.9655 1.39847 13.9274 1.59849 13.9311 1.79992C13.9347 2.00135 13.98 2.19994 14.0642 2.38396C14.1484 2.56798 14.2698 2.73355 14.4212 2.87089L20.3412 8.03781L2.09863 8.03804C1.24819 8.03804 0.557983 8.70998 0.557983 9.53807Z" fill="black"/>
                            </svg>
                        </div>
                        <div className='div_img_hover'>
                            <img src={logo_beacon} ></img>
                        </div>
                    </Link>
                    <Link to="details/4" className="cards">
                        <div className="cards_div">
                            <p className="cards_title">Portfolio</p>
                        </div>
                        <div className="cards_div_tech">
                            <p className="cards_div_tech_text">Branding</p>
                            <p className="cards_div_tech_text">UX/UI</p>
                            <p className="cards_div_tech_text">Développement Web</p>
                            <svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.557983 9.53807C0.557983 9.93594 0.720302 10.3174 1.00923 10.5987C1.29816 10.8801 1.69003 11.0381 2.09863 11.0381L20.3412 11.0379L14.8567 16.4167C14.5846 16.7011 14.4364 17.0772 14.4435 17.4658C14.4505 17.8545 14.6122 18.2251 14.8945 18.5C15.1767 18.7749 15.5576 18.9323 15.9567 18.9391C16.3558 18.946 16.7421 18.8017 17.0342 18.5368L25.1481 10.5979C25.4366 10.3166 25.5986 9.93539 25.5986 9.53784C25.5986 9.1403 25.4366 8.75911 25.1481 8.47782L16.5986 0.750861C16.4576 0.603471 16.2875 0.485287 16.0985 0.403294C15.9095 0.321301 15.7055 0.277225 15.4987 0.273671C15.2918 0.270117 15.0863 0.30716 14.8945 0.38261C14.7026 0.45806 14.5284 0.57033 14.3821 0.712783C14.2358 0.855237 14.1204 1.02488 14.043 1.21167C13.9655 1.39847 13.9274 1.59849 13.9311 1.79992C13.9347 2.00135 13.98 2.19994 14.0642 2.38396C14.1484 2.56798 14.2698 2.73355 14.4212 2.87089L20.3412 8.03781L2.09863 8.03804C1.24819 8.03804 0.557983 8.70998 0.557983 9.53807Z" fill="black"/>
                            </svg>
                        </div>
                        <div className='div_img_hover'>
                            <img src={logo_evan} ></img>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Projets;