import './Navbar.css';
import CV from '../../documents/CV_Evan_Noubel.pdf'
import { HashLink } from 'react-router-hash-link';

function Navbar() {
    return (
        <div className='Navbar'>
            <HashLink to={CV} target="_blank" className="btn_work">
                Disponible pour travailler 
            </HashLink>
            <div className="Title">
                EVAN NOUBEL
            </div>
            <HashLink to="#Contact" className="btn_contact">
                <div className='circle'></div>
                Contact
            </HashLink>
        </div>
    );
}

export default Navbar;