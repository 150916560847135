import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Loader from './pages/Loader.js';
import Home from './pages/Home.js';
import Details from './pages/Details.js';

function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path="*" element={<Loader />} />
          <Route path="/" element={<Loader />} />
          <Route path="/home" element={<Home />} />
          <Route path="/home/details/:id" element={<Details />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;