import './Experience.css';

function Experience() {
    return (
        <div className='Experience'>
            <div className='Title_div'>
                <svg className='Title_svg' width="43" height="37" viewBox="0 0 43 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M28.4063 8.77704C28.0002 8.76643 27.6002 8.87782 27.2581 9.09685C26.9159 9.31587 26.6473 9.63245 26.4869 10.0057C26.3265 10.3789 26.2818 10.7917 26.3584 11.1906C26.435 11.5896 26.6295 11.9564 26.9167 12.2437L37.5333 22.7791L26.9167 33.3145C26.5363 33.6954 26.3226 34.2117 26.3226 34.75C26.3226 35.2882 26.5363 35.8045 26.9167 36.1854C27.2975 36.5658 27.8138 36.7794 28.3521 36.7794C28.8904 36.7794 29.4066 36.5658 29.7875 36.1854L41.8667 24.2145C42.2471 23.8337 42.4607 23.3174 42.4607 22.7791C42.4607 22.2408 42.2471 21.7246 41.8667 21.3437L29.7875 9.37287C29.6101 9.18457 29.396 9.03457 29.1585 8.9321C28.921 8.82963 28.665 8.77686 28.4063 8.77704Z" fill="black"/>
                    <path d="M2.54167 0.218758C2.00513 0.225771 1.49254 0.44203 1.11312 0.821453C0.733694 1.20088 0.517436 1.71347 0.510422 2.25001V22.7792C0.517436 23.3157 0.733694 23.8283 1.11312 24.2077C1.49254 24.5872 2.00513 24.8034 2.54167 24.8104H40.4583C40.9971 24.8104 41.5137 24.5964 41.8947 24.2155C42.2756 23.8346 42.4896 23.3179 42.4896 22.7792C42.4896 22.2405 42.2756 21.7238 41.8947 21.3429C41.5137 20.9619 40.9971 20.7479 40.4583 20.7479H4.57292V2.25001C4.56591 1.71347 4.34965 1.20088 3.97023 0.821453C3.5908 0.44203 3.07821 0.225771 2.54167 0.218758Z" fill="black"/>
                </svg>
                <p className='Title_text'>Experience</p>
            </div>
            <div>
                <div className='container_exp'>
                    <p className='date'>2024 - 2025</p>
                    <div className='description'>
                        <p className='description_title'>ECV Paris</p>
                        <p className='description_desc'>Bac+3 en UI Design</p>
                    </div>
                </div>
                <div className='line'></div>
                <div className='container_exp'>
                    <p className='date'>2023 - 2024</p>
                    <div className='description'>
                        <p className='description_title'>CDI chez Exalog, a Cegid Company </p>
                        <p className='description_desc'>Logiciels de gestion des paiements et de trésorerie</p>
                    </div>
                </div>
                <div className='line'></div>
                <div className='container_exp'>
                    <p className='date'>2022 - 2023</p>
                    <div className='description'>
                        <p className='description_title'>Alternance chez Bibi Sorties</p>
                        <p className='description_desc'>Start-up qui vise à mettre en avant des sorties culturel  dans toute la France</p>
                    </div>
                </div>
                <div className='line'></div>
                <div className='container_exp'>
                    <p className='date'>2021 - 2023</p>
                    <div className='description'>
                        <p className='description_title'>Epitech - Web@cadémie</p>
                        <p className='description_desc'>Obtention du Bac+2 en intégrateur dévéloppeur web</p>
                    </div>
                </div>
                <div className='line'></div>
            </div>
        </div>
    );
}

export default Experience;