import '../style/Home.css';
import Navbar from '../components/Navbar/Navbar'
import HOME_TOP from '../components/Home/Home';
import About from '../components/About/About';
import Experience from '../components/Experience/Experience';
import Projets from '../components/Projets/Projets';
import Contact from '../components/Contact/Contact';

function Home() {
    return (
        <div className="container_home">
            {/* <div className='bandeau'>
                <p>Cette application est en version bêta, certaines fonctionnalités peuvent ne pas fonctionner correctement.</p>
            </div> */}
            <Navbar />
            <HOME_TOP />
            <About />
            <Projets />
            <Experience />
            <Contact />
        </div>
    );
}

export default Home;
