import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Loader.css';

function Loader() {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/home');
        }, 2000);

        return () => clearTimeout(timer);
    }, [navigate]);

    const greetings = [
        'Bonjour',       // Français
        'Hello',         // Anglais
        'Hola',          // Espagnol
        'Hallo',         // Allemand
        'Ciao',          // Italien
        'Olá',           // Portugais
        'こんにちは',     // Japonais
        '안녕하세요',       // Coréen
        'नमस्ते',         // Hindi
        'مرحباً',         // Arabe
        '你好'           // Chinois
    ];

    const [currentGreetingIndex, setCurrentGreetingIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentGreetingIndex((prevIndex) =>
                prevIndex === greetings.length - 1 ? 0 : prevIndex + 1
            );
        }, 200);

        return () => clearInterval(interval);
    }, [greetings.length]);

    return (
        <div className="loader-container">
            <h1 className="greeting">
                {greetings[currentGreetingIndex]}
            </h1>
        </div>
    );
}

export default Loader;
